<template>
  <div class="map-section">
    <v-row no-gutters>
      <v-col cols="12" md="6" lg="6" xl="6" class="d-flex flex-column justify-space-between">
        <div>
          <p class="title-md"><span>Tenemos 9 sedes</span> a nivel nacional</p>
          <p class="subtitle">
            Estamos más cerca de ti y de tu hogar o tu centro de estudios,
            lo cual te permitirá ahorrar tiempo y administrarlo de la mejor manera,
            ¡queremos lo mejor para ti!
          </p>
        </div>

        <div class="d-none d-md-flex justify-end card-sede">
          <SlideSede :sedes="selectPoint.id ? [...selectPoint.id] : []"></SlideSede>
        </div>
      </v-col>
      <v-col cols="12" md="6" lg="6" xl="6">
        <div class="container-map">
          <v-img alt="mapa-mobile"
          contain
          :src="require('../../../assets/newlanding/Landing/mapa-mobile.svg')"></v-img>
          <div
            v-for="(p, i) in points"
            :class="`point-map-${i} pa-2 ${selectPoint.sede === p.sede ? 'actived-size-point-map' : 'size-point-map'}`"
            :key="i"
            @click="() => handleShowCard(p.sede)"
          >
            <v-img
              alt="point"
              contain
              :src="require('../../../assets/point-map.png')"
              class="pa-3 mt-1"
              v-if="selectPoint.sede === '' || selectPoint.sede !== p.sede"
            ></v-img>
            <v-img
              alt="point"
              contain
              :src="require('../../../assets/point-map-color.png')"
              class="pa-3 mt-1"
              v-if="selectPoint.sede === p.sede"
            ></v-img>
          </div>
        </div>
      </v-col>
      <v-col cols="12" class="d-md-none">
        <SlideSede :sedes="selectPoint.id ? [...selectPoint.id] : []"></SlideSede>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SlideSede from './SlideSede.vue';

export default {
  name: 'Map',
  components: { SlideSede },

  data() {
    return {
      selectPoint: {
        sede: '',
        id: null,
      },
      points: [
        {
          sede: '1',
          name: 'Piura',
          ubication: 'Av. Grau N° 1460 Manzana A.',
          img: 'Piura.svg',
        },
        {
          sede: '2',
          name: 'Chiclayo',
          ubication: 'Av. Sáenz Peña 396',
          img: 'Chiclayo.svg',
        },
        {
          sede: '3',
          name: 'Trujillo',
          ubication: 'Jirón Francisco Pizarro 221',
          img: 'Trujillo.svg',
        },
        {
          sede: '4',
          pointInfo: [
            {
              name: 'Crillón',
              ubication: 'Av. Nicolás de Piérola 589, Lima 15001',
              img: 'Crillon.svg',
            },
            {
              name: 'Fénix',
              ubication: 'Av. Nicolás de Piérola 1044',
              img: 'Fenix.svg',
            },
            {
              name: 'Carabaya',
              ubication: 'Jirón Carabaya 933, Lima 15001',
              img: 'Carabaya.svg',
            },
            {
              name: 'Surquillo',
              ubication: 'Avenida República de Panamá 4575',
              img: 'Surquillo.svg',
            },
          ],
        },
        {
          sede: '5',
          pointInfo: [
            {
              name: 'Bellavista',
              ubication: 'Avenida Óscar R. Benavides 3866',
              img: 'Bellavista.svg',
            },
            {
              name: 'Callao',
              ubication: 'Avenida Elmer Faucett 2823',
              img: 'Lima_cargo.svg',
            },
          ],
        },
      ],
    };
  },
  methods: {
    handleShowCard(sedeId) {
      const point = this.points.find(p => p.sede === sedeId);
      if (point) {
        if (point.pointInfo) {
          // Si el punto tiene un array 'pointInfo', usamos eso
          this.selectPoint.id = point.pointInfo;
        } else {
          // Si no, usamos el punto mismo
          this.selectPoint.id = [point];
        }
      } else {
        this.selectPoint.id = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./_app.scss";
.actived-size-point-map {
  cursor: pointer;
  width: 48px;
}
.size-point-map {
  cursor: pointer;
  width: 45px;
}
.point-map-0 {
  z-index: 1;
  position: absolute;
  left: 0px;
  top: 50px;
}

.point-map-1 {
  z-index: 1;
  position: absolute;
  top: 80px;
  left: 10px;
}

.point-map-2 {
  z-index: 1;
  position: absolute;
  top: 110px;
  left: 40px;
}

.point-map-3 {
  z-index: 1;
  position: absolute;
  top: 165px;
  left: 80px;
}

.point-map-4 {
  z-index: 1;
  position: absolute;
  top: 190px;
  left: 80px;
}

.subtitle {
  font-family: $principal-font !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: $secondary-blueLight !important;
  line-height: 18px;
  width: 290px;

  span {
    font-weight: 700;
  }
}

.map-section {
  padding: 5% 5%;
}
.container-map {
  position: relative;
  width: 290px;
  height: 370px;
  margin: 0% 0% 8% 0%;
}

.title-md {
  font-family: $principal-font !important;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: $secondary-fucsia;
  line-height: 25px;
  width: 200px;

  span {
    font-family: $principal-font !important;
    font-weight: 600;
  }
}

@media (min-width: 374px) {
  .subtitle {
    width: 325px;
  }
}

@media (min-width: 600px) {
  .container-map {
    width: 350px;
    height: 420px;
    margin: 2% 0% 8% 0%;
  }

  .title-md {
    width: 450px;
    font-size: 30px;
    line-height: 50px;
  }
  .subtitle {
    font-size: 18px;
    width: 500px;
  }

  .point-map-0 {
    left: 0px;
    top: 75px;
  }

  .point-map-1 {
    top: 100px;
    left: 15px;
  }

  .point-map-2 {
    top: 140px;
    left: 60px;
  }

  .point-map-3 {
    top: 210px;
    left: 100px;
  }

  .point-map-4 {
    top: 240px;
    left: 100px;
  }
}

@media (min-width: 720px) and (max-width: 956px) {
  .container-map {
    width: 390px;
    height: 480px;
    margin: 2% 0% 8% 0%;
    left: 20%;
  }
  .point-map-0 {
    left: 0px;
    top: 80px;
  }

  .point-map-1 {
    top: 120px;
    left: 18px;
  }

  .point-map-2 {
    top: 160px;
    left: 60px;
  }

  .point-map-3 {
    top: 240px;
    left: 110px;
  }

  .point-map-4 {
    top: 270px;
    left: 115px;
  }
  .subtitle {
    line-height: 25px;
  }
}

@media (min-width: 956px) {
  .container-map {
    width: 460px;
    height: 600px;
    margin: 2% 0% 8% 0%;
  }

  .point-map-0 {
    top: 90px;
  }

  .point-map-1 {
    top: 135px;
    left: 20px;
  }

  .point-map-2 {
    top: 180px;
    left: 80px;
  }

  .point-map-3 {
    top: 270px;
    left: 125px;
  }

  .point-map-4 {
    top: 310px;
    left: 130px;
  }

  .title-md {
    font-size: 40px;
  }
  .subtitle {
    width: 400px;
    font-size: 18px;
    line-height: 35px;
  }

  .actived-size-point-map {
    width: 57px;
  }
  .size-point-map {
    width: 55px;
  }
}

@media (min-width: 1160px) {
  .actived-size-point-map {
    width: 59px;
  }
  .size-point-map {
    width: 57px;
  }
}

@media (min-width: 1264px) {
  .title-md {
    font-size: 48px;
    width: 390px;
  }
  .subtitle {
    width: 500px;
  }
  .container-map {
    left: 2%;
  }
}

@media (min-width: 1360px) {
  .container-map {
    width: 600px;
    height: 720px;
  }
  .point-map-0 {
    top: 140px;
    left: 10px;
  }

  .point-map-1 {
    top: 195px;
    left: 40px;
  }

  .point-map-2 {
    top: 260px;
    left: 100px;
  }

  .point-map-3 {
    top: 360px;
    left: 175px;
  }

  .point-map-4 {
    top: 410px;
    left: 180px;
  }
}
@media (min-width: 1903px) {
  .container-map {
    width: 713px;
    height: 865px;
  }
  .title-md {
    font-size: 48px;
  }
  .subtitle {
    width: 680px;
  }
  .point-map-0 {
    top: 180px;
    left: 10px;
  }

  .point-map-1 {
    top: 240px;
    left: 50px;
  }

  .point-map-2 {
    top: 310px;
    left: 120px;
  }

  .point-map-3 {
    top: 450px;
    left: 210px;
  }

  .point-map-4 {
    top: 500px;
    left: 220px;
  }
}
</style>
