<template>
  <div class="card-slide" v-if="sedes.length > 0 && sedes[0].img">
    <v-carousel
      v-if="sedes.length > 1"
      hide-delimiters
      height="auto" class="card rounded-xl elevation-2"
    >
      <v-carousel-item v-for="i in sedes" :key="i.name" v-if="i.img" :src="require(`../../../assets/newlanding/Sedes/${i.img}`)">
        <div class="slide-titles container-titles">
          <p class="white--text container-titles-subtitles mb-0 pl-5 pt-1">{{ i.name }}</p>
          <span class="white--text d-md-block pl-5">{{ i.ubication }}</span>
        </div>
      </v-carousel-item>
    </v-carousel>
    <v-card
      class="rounded-xl elevation-1 d-flex flex-column align-start justify-end card mt-15 mt-sm-6"
      v-else
    >
      <v-img v-if="sedes[0]?.img" :src="require(`../../../assets/newlanding/Sedes/${sedes[0]?.img}`)" class="rounded-xl">
      </v-img>
      <div class="container-titles pl-6 py-2">
        <p class="white--text container-titles-subtitles mb-0">{{ sedes[0]?.name }}</p>
        <span class="white--text d-md-block">{{ sedes[0]?.ubication }}</span>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'SlideSede',
  data() {
    return {
      items: [
        {
          src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
        },
        {
          src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
        },
        {
          src: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',
        },
        {
          src: 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',
        },
      ],
    };
  },
  props: {
    sedes: {
      type: Array,
      default: () => [
        {
          name: '',
          ubication: '',
          img: '',
        },
      ],
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./_app.scss";

.card {
  width: 316px;
  height: 119px;
}

.card-slide {
  width: auto;
  height: auto;
}
.container-titles {
  width: 100%;
  position: absolute;
  background-color: rgba(2, 2, 2, 0.35);
  span {
    font-family: $principal-font !important;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
  }
}

.slide-titles {
  position: absolute;
  background-color: rgba(2, 2, 2, 0.35);
  width: 100%;
  bottom: 0%;
  height: 55px;
}

.container-titles-subtitles {
  font-family: $principal-font !important;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
}

@media (min-width: 610px) {
  .card {
    width: 450px;
    height: 290px;
  }
  .slide-titles {
    position: absolute;
    background-color: rgba(2, 2, 2, 0.35);
    width: 100%;
    bottom: 0%;
    height: 61px;
  }
}
@media (min-width: 948px) {
  .card {
    width: 450px;
    height: 290px;
  }
  .slide-titles {
    height: 60px;
  }
}
@media (min-width: 1023px) {
  .card {
    width: 400px;
    height: 300px;
  }
  .slide-titles {
    height: 60px;
  }
}

@media (min-width: 1360px) {
  .slide-titles {
    height: 60px;
  }
  .card {
    width: 450px;
    height: 270px;
  }

  .card-slide {
    padding-bottom: 80px;
  }
}
@media (min-width: 1510px) {
  .card-container {
    position: relative;
    left: 6%;
    top: 0%;
  }
  .slide-titles {
    height: 70px;
  }

  .card {
    width: 611px;
    height: 392px;
  }
}
</style>
